import { SecretQuestionData } from "Shared/SecretQuestionsForm/interfaces";
import { jsendResponseDecoder } from "Utils/jsend";
import { IdValidation, requiredValidation } from "Utils/validations";
import { boolean, DecoderType, nullable, number, object, string } from "decoders";
import * as Yup from "yup";

export interface BasicDialogProps {
  open: boolean;
  onClose: () => void;
}

export type LoginStatus = "login" | "secret-questions";

export interface LoginData {
  status: LoginStatus;
  secretQuestions?: SecretQuestionData[];
  uid?: string;
  token?: string;
}

export interface LoginFormData {
  clientId: string;
  password: string;
}

const userDecoderKey = object({
  pk: number,
  username: string,
  first_name: string,
  last_name: string,
  email: string,
  full_name: string,
  is_active: boolean,
  last_login: nullable(string),
  role: string,
  role_id: nullable(number),
  affiliated_institution: boolean,
  lead_id: string,
  pending_signature: boolean,
});

export type UserData = DecoderType<typeof userDecoderKey>;

export const loginDataKeys: ["clientId", "password"] = ["clientId", "password"];

const baseReponseFailDecoder = object({
  errorKey: string,
  message: string,
});

export type BaseResponseFail = DecoderType<typeof baseReponseFailDecoder>;

const loginResponseSuccessDecoder = object({
  uid: string,
  token: string,
});

export type LoginResponseSuccess = DecoderType<typeof loginResponseSuccessDecoder>;

export const loginResponseDecoder = jsendResponseDecoder(loginResponseSuccessDecoder, baseReponseFailDecoder);

export type LoginResponse = DecoderType<typeof loginResponseDecoder>;

const getTokenDataDecoder = object({
  access: string,
  refresh: string,
  user: userDecoderKey,
});

export type GetTokenData = DecoderType<typeof getTokenDataDecoder>;

export const getTokenResponseDecoder = jsendResponseDecoder(getTokenDataDecoder, baseReponseFailDecoder);

export type GetTokenResponse = DecoderType<typeof getTokenResponseDecoder>;

export const getCurrentUserResponseDecoder = jsendResponseDecoder(userDecoderKey, string);

export type GetCurrentUserResponse = DecoderType<typeof getCurrentUserResponseDecoder>;

export const loginDataSchema = Yup.object({
  [loginDataKeys[0]]: IdValidation,
  [loginDataKeys[1]]: requiredValidation,
});
