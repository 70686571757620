import {
  array,
  boolean,
  constant,
  Decoder,
  DecoderType,
  either,
  nullable,
  number,
  object,
  positiveInteger,
  positiveNumber,
  string,
  tuple,
} from "decoders";
import { FormikMoneyField } from "Shared/FormikMoney/FormikMoney";
import { jsendResponseDecoder, JsendSuccess, jsendSuccessDecoder } from "Utils/jsend";
import { choicesDecoder } from "../interfaces";

const certificateRateDecoder = object({
  min: number,
  max: number,
  minTimeLimit: positiveInteger,
  maxTimeLimit: positiveInteger,
  capitalizedInterestRate: number,
});

const certificateLimitsDecoder = object({
  minimumBalance: number,
  minTimeLimit: positiveInteger,
  maxTimeLimit: positiveInteger,
  description: array(certificateRateDecoder),
});

export const certificateLimitsResponseDecoder = jsendSuccessDecoder(certificateLimitsDecoder);
export type CertificateLimitsResponse = DecoderType<typeof certificateLimitsResponseDecoder>;

const certificateRequestDecoder = object({
  uniqueId: string,
  status: string,
  type: string,
  contractDate: string,
  signatureRequired: boolean,
});

export type CertificateRequest = DecoderType<typeof certificateRequestDecoder>;

export const certificateRequestResponseDecoder = jsendSuccessDecoder(certificateRequestDecoder);

export type CertificateRequestResponse = DecoderType<typeof certificateRequestResponseDecoder>;

const savingsPlanPoolDecoder = object({
  planDepositMin: number,
  planGoalMin: number,
  planTermMin: positiveInteger,
  planTermMax: positiveInteger,
  planInterest: number,
});

export const savingsPlanPoolResponseDecoder = jsendSuccessDecoder(savingsPlanPoolDecoder);
export type SavingsPlanPoolResponse = DecoderType<typeof savingsPlanPoolResponseDecoder>;

const savingsDepositLimitsDecoder = object({
  minMonthlyDeposit: number,
  maxMonthlyDeposit: number,
});

export const savingsDepositLimitsResponseDecoder = jsendSuccessDecoder(savingsDepositLimitsDecoder);
export type SavingsDepositLimitsResponse = DecoderType<typeof savingsDepositLimitsResponseDecoder>;

const prequalificationOptionsDecoder = object({
  numberOfDependents: choicesDecoder,
  typeOfHousing: choicesDecoder,
  levelOfEducation: choicesDecoder,
});

export type PrequalificationOptions = DecoderType<typeof prequalificationOptionsDecoder>;

export type PrequalificationOptionsResponse = JsendSuccess<PrequalificationOptions>;
export const prequalificationOptionsResponseDecoder: Decoder<PrequalificationOptionsResponse> =
  jsendSuccessDecoder(prequalificationOptionsDecoder);

const prequalificationValues = {
  salary: nullable(number),
  dateOfHire: string,
  numberOfDependents: string,
  typeOfHousing: string,
  levelOfEducation: string,
};

const prequalificationFieldsDecoder = object({ ...prequalificationValues });
export type PrequalificationFields = DecoderType<typeof prequalificationFieldsDecoder>;

export type PrequalificationFieldsResponse = JsendSuccess<PrequalificationFields>;
export const prequalificationFieldsResponseDecoder: Decoder<PrequalificationFieldsResponse> =
  jsendSuccessDecoder(prequalificationFieldsDecoder);

const userPrequalificationAdditionalValues = {
  isFirstCredit: boolean,
  affiliatedInstitution: boolean,
};

const userPrequalificationAdditionalValuesDecoder = object({
  ...userPrequalificationAdditionalValues,
});
export type UserPrequalificationAdditionalValues = DecoderType<typeof userPrequalificationAdditionalValuesDecoder>;

const userPrequalificationValuesDecoder = object({
  ...prequalificationValues,
  ...userPrequalificationAdditionalValues,
});
export type UserPrequalificationValues = DecoderType<typeof userPrequalificationValuesDecoder>;

export type UserPrequalificationValuesResponse = JsendSuccess<UserPrequalificationValues>;
export const userPrequalificationValuesResponseDecoder: Decoder<UserPrequalificationValuesResponse> =
  jsendSuccessDecoder(userPrequalificationValuesDecoder);

export type PrequalificationCalculationResponse = JsendSuccess<number>;
export const prequalificationCalculationResponseDecoder: Decoder<PrequalificationCalculationResponse> =
  jsendSuccessDecoder(number);

const updatePrequalification = object({
  prequalification: number,
  savingsWarrantyRate: number,
  savingsBalance: number,
  loanBalance: number,
});
export type UpdatePrequalification = DecoderType<typeof updatePrequalification>;
export const updatePrequalificationResponseDecoder = jsendSuccessDecoder(updatePrequalification);
export type UpdatePrequalificationResponse = DecoderType<typeof updatePrequalificationResponseDecoder>;

export interface SavingsAccountPayload {
  type: string;
  childName: string;
  childAge: string;
  birthCertificate: null | undefined;
  initialDeposit: FormikMoneyField;
  salary: FormikMoneyField;
  sourceOfFundsDesc: string;
  sourceOfFundsFile: null | undefined;
  monthlyDeposit: FormikMoneyField;
}

const savingsRequestDecoder = object({
  uniqueId: string,
  status: string,
  type: string,
  openingDate: string,
  signatureRequired: boolean,
});

export type SavingsRequest = DecoderType<typeof savingsRequestDecoder>;

export const savingsRequestResponseDecoder = jsendSuccessDecoder(savingsRequestDecoder);

export type SavingsRequestResponse = DecoderType<typeof savingsRequestResponseDecoder>;

export interface SavingsPlanPayload {
  typeOfPlan: "" | "quota" | "amount" | "periods";
  planDeposit: FormikMoneyField;
  planGoal: FormikMoneyField;
  planTerm: number;
  periodicity: string;
}

const savingsPlanDecoder = object({
  interestRate: number,
  table: array(
    tuple(
      positiveInteger, // Periodo
      string, // Fecha
      string, // Balance Inicial
      string, // Cuota
      string, // Interes
      string, // Retencion
      string // Balance Final
    )
  ),
});

export type SavingsPlan = DecoderType<typeof savingsPlanDecoder>;

export const savingsPlanResponseDecoder = jsendSuccessDecoder(savingsPlanDecoder);

export type SavingsPlanResponse = DecoderType<typeof savingsPlanResponseDecoder>;

const signingDataDecoder = object({
  state: either(constant("required"), constant("not_required"), constant("invalid_client"), constant("signed")),
  url: string,
});

export type SigningData = DecoderType<typeof signingDataDecoder>;

export const signingDataResponseDecoder = jsendResponseDecoder(signingDataDecoder, string);

export type SigningDataResponse = DecoderType<typeof signingDataResponseDecoder>;

const productRequestDecoder = object({
  id: string,
  status: string,
  statusDisplay: string,
  progress: number,
  type: either(constant("savings"), constant("certificate"), constant("loan")),
  product: string,
  created: string,
  signatureRequired: boolean,
});

export type ProductRequest = DecoderType<typeof productRequestDecoder>;

export const productRequestResponseDecoder = jsendSuccessDecoder(array(productRequestDecoder));

export type ProductRequestResponse = DecoderType<typeof productRequestResponseDecoder>;

export interface EditAccountPayload {
  code: string;
  channel: string;
  account: string | number | null;
  monthlyDeposit: number;
}

export interface LoanDataPayload {
  amount: null | number;
  term: number;
  paymentDay: number;
  includeSavingsWarranty: boolean;
  includeAffiliationFees: boolean;
}

const loanDataResponse = object({
  amount: number,
  administrativeCharges: number,
  amountAfterCharges: number,
  includeSavingsWarranty: boolean,
  savingsWarranty: number,
  includeAffiliationFees: boolean,
  affiliationFees: number,
  term: positiveInteger,
  interestRate: positiveNumber,
  payment: string,
  paymentDay: positiveInteger,
  secondPaymentDay: positiveInteger,
  paymentPlan: array(
    tuple(
      number, // Periodo
      string, // Fecha Vencimiento
      string, // Balance Principal
      string, // Cuota
      string, // Principal
      string, // Interes
      string, // Otro seguro
      string, // Seguro
      string, // Ahorro
      string, // Aportaciones
      string // Balance Final
    )
  ),
});

export type LoanDataResponse = DecoderType<typeof loanDataResponse>;

export const loanDataJsendResponseDecoder = jsendSuccessDecoder(loanDataResponse);
export type LoanDataJsendResponseDecoder = DecoderType<typeof loanDataJsendResponseDecoder>;

export interface LoanRequestPayload extends LoanDataPayload {
  account: string | number | null;
  addressCert?: null;
}

const loanRequestDecoder = object({
  uniqueId: string,
  status: string,
  type: string,
  creationDate: string,
  signatureRequired: boolean,
});

export type LoanRequestData = DecoderType<typeof loanRequestDecoder>;

export const loanRequestResponseDecoder = jsendSuccessDecoder(loanRequestDecoder);

export type LoanRequestResponse = DecoderType<typeof loanRequestResponseDecoder>;
