import { FormikImageField } from "Shared/FormikImageField/FormikImageField";
import { FormikMoney, FormikMoneyField } from "Shared/FormikMoney/FormikMoney";
import { FormikSlider } from "Shared/FormikSlider/FormikSlider";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { SourceOfFunds, useSourceOfFundsSchema } from "Shared/SourceOfFunds/SourceOfFunds";
import { requiredValidation } from "Utils/validations";
import { Fragment } from "react";
import { useNumericFormat } from "react-number-format";
import * as Yup from "yup";

export type SavingsAccountType = "in_sight" | "youth";

const numericConfig = {
  allowNegative: false,
  decimalScale: 2,
  thousandSeparator: ",",
  fixedDecimalScale: true,
};

interface GeneralProps {
  minMonthlyDeposit?: number;
  maxMonthlyDeposit?: number;
  institutionIsAffiliated: boolean;
  dbSalary: FormikMoneyField;
  isRegistration?: boolean;
}

interface SavingsAccountFormProps extends GeneralProps {
  type: SavingsAccountType;
}

export function SavingsAccountForm({
  minMonthlyDeposit = 0,
  maxMonthlyDeposit = 0,
  type,
  institutionIsAffiliated,
  dbSalary,
  isRegistration = false,
}: SavingsAccountFormProps) {
  const { format } = useNumericFormat<typeof FormikTextField>({ ...numericConfig });

  const showInitialDeposit = !isRegistration || (isRegistration && type === "in_sight");
  const showMonthlyDeposit = !isRegistration || (isRegistration && type === "youth");

  return (
    <Fragment>
      {type === "youth" && <ChildAccount />}
      {showInitialDeposit && (
        <FormikMoney
          id="initialDeposit"
          name="initialDeposit"
          label={isRegistration ? "Digitar aumento" : "Balance de apertura"}
          placeholder={isRegistration ? "" : "Opcional"}
          variant="outlined"
        />
      )}
      <SourceOfFunds name="initialDeposit" institutionIsAffiliated={institutionIsAffiliated} dbSalary={dbSalary} />
      {showMonthlyDeposit && (
        <FormikSlider
          id="monthlyDeposit"
          name="monthlyDeposit"
          label="Cuota"
          aria-label="Cuota"
          valueLabelDisplay="auto"
          min={minMonthlyDeposit}
          max={maxMonthlyDeposit}
          step={200}
          marks
          formatValue={(value) => `RD$${format(value)}`}
        />
      )}
    </Fragment>
  );
}

function ChildAccount() {
  return (
    <Fragment>
      <FormikTextField id="childName" name="childName" label="Nombre del niño" variant="outlined" required />
      <FormikTextField
        id="childAge"
        name="childAge"
        label="Edad del niño"
        variant="outlined"
        required
        type="number"
        step="1"
        min="0"
      />
      <FormikImageField
        name="birthCertificate"
        label="Acta de nacimiento"
        containerProps={{ spacing: 1, width: "100%" }}
      />
    </Fragment>
  );
}

export function useSavingsAccountFormSchema({
  minMonthlyDeposit = 0,
  maxMonthlyDeposit = 0,
  institutionIsAffiliated,
  dbSalary,
  isRegistration = false,
}: GeneralProps) {
  const { format } = useNumericFormat<typeof FormikTextField>({ ...numericConfig });
  const sourceOfFundsSchema = useSourceOfFundsSchema("initialDeposit", institutionIsAffiliated, dbSalary);

  let monthlyDepositSchema = Yup.object({});
  const monthlyDepositNumberSchema = Yup.number()
    .required("Requerido")
    .integer("Debe ser un entero")
    .positive("Debe ser un entero positivo")
    .min(minMonthlyDeposit, `No menos de RD$${format(minMonthlyDeposit.toString())}`)
    .max(maxMonthlyDeposit, `No mas de RD$${format(maxMonthlyDeposit.toString())}`);

  if (isRegistration) {
    monthlyDepositSchema = Yup.object({
      monthlyDeposit: Yup.number().when("type", {
        is: "youth",
        then: () => monthlyDepositNumberSchema,
      }),
    });
  } else {
    monthlyDepositSchema = Yup.object({
      monthlyDeposit: monthlyDepositNumberSchema,
    });
  }

  return Yup.object({
    type: requiredValidation,
    childName: Yup.string().when("type", {
      is: "youth",
      then: (schema) => schema.required("Requerido"),
    }),
    childAge: Yup.number()
      .integer("Debe ser un entero")
      .positive("Debe ser un entero positivo")
      .when("type", {
        is: "youth",
        then: (schema) => schema.required("Requerido"),
      }),
    birthCertificate: Yup.mixed().when("type", {
      is: "youth",
      then: (schema) => schema.required("Archivo requerido"),
    }),
    initialDeposit: Yup.number().nullable(),
  })
    .concat(monthlyDepositSchema)
    .concat(sourceOfFundsSchema);
}
