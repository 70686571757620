import { Button, ButtonProps, FormHelperText, Popover, Stack, StackProps } from "@mui/material";
import { useField, useFormikContext } from "formik";
import FileIcon from "Icons/FileIcon";
import { ReactNode, useEffect, useState } from "react";
import ImageUploading, { ImageListType } from "react-images-uploading";

interface FormikImageFieldProps {
  name: string;
  label?: string;
  containerProps?: StackProps;
  buttonProps?: ButtonProps;
  help?: ReactNode;
}

export function FormikImageField(props: FormikImageFieldProps): JSX.Element {
  const { name, label, help, containerProps = {}, buttonProps = {} } = props;
  const [{ value }, { error }, { setValue }] = useField<File | undefined>(props);
  const { isSubmitting } = useFormikContext<{ [k: string]: string }>();
  const [images, setImages] = useState<ImageListType>([]);
  const [touched, setTouched] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const buttonDisplay = value ? "Cambiar archivo" : "Adjuntar archivo";

  const onImageChange = (imageList: ImageListType) => {
    setImages(imageList);
    setValue(imageList[0].file);
  };

  useEffect(() => {
    if (isSubmitting) setTouched(true);
  }, [isSubmitting, setTouched]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const popoverId = open ? `${props.name}-popover` : undefined;

  return (
    <ImageUploading
      value={images}
      onChange={onImageChange}
      acceptType={["jpg", "jpeg", "png", "pdf"]}
      maxFileSize={6291456}
      dataURLKey="data_url"
      allowNonImageType
    >
      {({ onImageUpload, errors }) => (
        <Stack spacing={1} {...containerProps}>
          <Stack direction="row" alignItems="center" flexWrap="wrap" spacing={1}>
            {label && <label htmlFor={name}>{label}</label>}
            {help && (
              <Button variant="text" onClick={handleClick} sx={{ p: 0 }}>
                ¿Qué es esto?
              </Button>
            )}
          </Stack>
          <Button
            variant="outlined"
            startIcon={<FileIcon />}
            disabled={isSubmitting}
            onClick={onImageUpload}
            {...buttonProps}
          >
            {buttonDisplay}
          </Button>
          <FormHelperText>
            {value
              ? value.name
              : "El archivo debe estar en formato jpg, png o pdf, y pesar menos de 6 MB. 1 solo archivo."}
          </FormHelperText>
          {errors?.maxFileSize && <FormHelperText error>El archivo no puede ser mayor de 6 MB</FormHelperText>}
          {touched && error && <FormHelperText error> {error}</FormHelperText>}
          <Popover
            id={popoverId}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            {help}
          </Popover>
        </Stack>
      )}
    </ImageUploading>
  );
}
