import { FormikMoneyField } from "Shared/FormikMoney/FormikMoney";
import { JsendResponse, JsendSuccess, jsendResponseDecoder, jsendSuccessDecoder } from "Utils/jsend";
import {
  Decoder,
  DecoderType,
  array,
  boolean,
  constant,
  either,
  integer,
  nullable,
  number,
  object,
  optional,
  string,
  tuple,
  undefined_,
} from "decoders";
import {
  baseOptionDecoder,
  departmentOptionDecoder,
  nationalityOptionDecoder,
  positionOptionDecoder,
  professionOptionDecoder,
  regionOptionDecoder,
} from "../crm/interfaces";
import { choicesDecoder } from "../interfaces";
import { OtpValidationData } from "../otp/interfaces";
import { SavingsAccountPayload, SavingsPlanPayload } from "../request/interfaces";

const registrationStatusDecoder = either(
  constant("client-verification"),
  constant("terms"),
  constant("name"),
  constant("affiliation-purpose"),
  constant("email-validation"),
  constant("phone-validation"),
  constant("identity-verification"),
  constant("loan"),
  constant("certificate"),
  constant("savings"),
  constant("terms"),
  constant("affiliation-terms"),
  constant("client-configuration"),
  constant("user-configuration"),
  constant("complete")
);

export type RegistrationStatus = DecoderType<typeof registrationStatusDecoder>;

const registrationModelDecoder = object({
  id: string,
  status: registrationStatusDecoder,
  isAffiliatedClient: boolean,
  name: optional(string),
  partialEmail: optional(string),
  partialNumber: optional(string),
  institutionIsAffiliated: boolean,
  purpose: either(constant(""), constant("savings"), constant("financing"), constant("certificate")),
  salary: nullable(number),
  isResumable: boolean,
});

export type RegistrationModel = DecoderType<typeof registrationModelDecoder>;

export type RegistrationModelSuccessResponse = JsendSuccess<RegistrationModel>;
export const registrationModelSuccessResponseDecoder = jsendSuccessDecoder(registrationModelDecoder);

const incomeSourceChoicesDecoder = object({
  salaried: choicesDecoder,
  nonSalaried: choicesDecoder,
});
export type IncomeSourceChoices = DecoderType<typeof incomeSourceChoicesDecoder>;
export const incomeSourceChoicesResponseDecoder = jsendSuccessDecoder(incomeSourceChoicesDecoder);
export type IncomeSourceChoicesResponse = DecoderType<typeof incomeSourceChoicesResponseDecoder>;

export interface StartRegistrationRequest {
  clientId: string;
  incomeSource: string;
  name: string;
  rnc: string;
}

const startRegistrationResponseFailDecoder = object({
  errorKey: either(constant("nonActiveAffiliatedClient"), constant("affiliatedClientWithAccess")),
  message: string,
});

export type StartRegistrationResponseFail = DecoderType<typeof startRegistrationResponseFailDecoder>;

export type StartRegistrationResponse = JsendResponse<RegistrationModel, StartRegistrationResponseFail>;

export const startRegistrationResponseDecoder = jsendResponseDecoder(
  registrationModelDecoder,
  startRegistrationResponseFailDecoder
);

const affiliationPurposeDecoder = object({
  financing: boolean,
  savings: boolean,
  certificate: boolean,
});

export type AffiliationPurpose = DecoderType<typeof affiliationPurposeDecoder>;

export type ConfirmChannelResponse = JsendResponse<string, string>;

export const confirmChannelResponseDecoder: Decoder<ConfirmChannelResponse> = jsendResponseDecoder(string, string);

export interface SaveContactRequest extends OtpValidationData {
  id: RegistrationModel["id"];
}

export type SaveContactResponse = JsendResponse<{ status: RegistrationModel["status"] }, string>;

export const saveContactResponseDecoder: Decoder<SaveContactResponse> = jsendResponseDecoder(
  object({
    status: registrationStatusDecoder,
  }),
  string
);

export const prequalificationResponseDecoder = jsendSuccessDecoder(
  object({
    affiliationFees: number,
    prequalification: number,
    savingsWarrantyRate: number,
  })
);

export type PrequalificationResponse = DecoderType<typeof prequalificationResponseDecoder>;

export type PrequalificationResponseData = PrequalificationResponse["data"];

export type SaveMetamapInfoResponse = SaveContactResponse;

export const saveMetamapInfoResponseDecoder: Decoder<SaveMetamapInfoResponse> = saveContactResponseDecoder;

const clientFormOptionsDecoder = object({
  nationalities: array(nationalityOptionDecoder),
  professions: array(professionOptionDecoder),
  regions: array(regionOptionDecoder),
  jobStatus: choicesDecoder,
  jobTypes: choicesDecoder,
  paymentTypes: choicesDecoder,
  sessionTypes: choicesDecoder,
  positions: array(positionOptionDecoder),
  departments: array(departmentOptionDecoder),
  economicSectors: array(baseOptionDecoder),
});

export type ClientFormOptions = DecoderType<typeof clientFormOptionsDecoder>;

export type ClientFormOptionsResponse = JsendSuccess<ClientFormOptions>;
export const clientFormOptionsResponseDecoder: Decoder<ClientFormOptionsResponse> =
  jsendSuccessDecoder(clientFormOptionsDecoder);

export const PersonalFormDataDecoder = object({
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  nationality: string,
  profession: string,
});

export const AddressFormDataDecoder = object({
  country: string,
  region: string,
  province: string,
  city: string,
  sector: string,
  line1: string,
  line2: string,
  addressCert: nullable(undefined_),
});

export const WorkFormDataDecoder = object({
  salary: nullable(number),
  salaryWasCaptured: boolean,
  dateOfHire: string,
  dateOfHireWasCaptured: boolean,
  paymentType: string,
  sessionType: string,
  position: string,
  department: string,
  shouldCaptureInstitution: boolean,
  institutionEmail: string,
  institutionPhone: string,
  economicSector: string,
});

const clientFormDataDecoder = object({
  personal: PersonalFormDataDecoder,
  address: AddressFormDataDecoder,
  work: WorkFormDataDecoder,
});

export type ClientFormData = DecoderType<typeof clientFormDataDecoder>;

export type GetClientDataResponse = JsendSuccess<ClientFormData>;
export const getClientDataResponseDecoder = jsendSuccessDecoder(clientFormDataDecoder);

const statusDecoder = object({ status: registrationStatusDecoder });

export const registrationSuccessStatusResponseDecoder = jsendSuccessDecoder(statusDecoder);

export type RegistrationSuccessStatusResponse = DecoderType<typeof registrationSuccessStatusResponseDecoder>;

export const registrationStatusResponseDecoder = jsendResponseDecoder(statusDecoder, string);

export type RegistrationStatusResponse = DecoderType<typeof registrationStatusResponseDecoder>;

const getClientIdDecoder = object({
  clientId: string,
});

export type GetClientId = DecoderType<typeof getClientIdDecoder>;

export type GetClientIdResponse = JsendSuccess<GetClientId>;
export const getClientIdResponseDecoder = jsendSuccessDecoder(getClientIdDecoder);

export interface UserFormData {
  password: string;
  confirm: string;
  question1: string;
  answer1: string;
  question2: string;
  answer2: string;
}

export interface ReadOnlySecurityQuestion {
  name: string;
  unique_id: string;
}

export interface ReadOnlyUserFormData {
  username: string;
  securityQuestions: ReadOnlySecurityQuestion[];
}

export type GetRegistrationResponse = JsendResponse<RegistrationModel, string>;

export const getRegistrationResponseDecoder = jsendResponseDecoder(registrationModelDecoder, string);

export interface NoEmployerCodePayload {
  to: string;
  code: string;
  clientId: string;
  name: string;
  rnc: string;
  salaried: boolean;
}

export const NoEmployerCodeResponseDecoder = jsendResponseDecoder(string, string);

export type NoEmployerCodeResponse = DecoderType<typeof NoEmployerCodeResponseDecoder>;

export interface SaveSavingsRequestPayload extends SavingsPlanPayload, SavingsAccountPayload {
  id: RegistrationModel["id"];
  editInitialDeposit: boolean;
  typeofPayment: string;
}

const statusSalarySuccessDecoder = object({ status: registrationStatusDecoder, salary: nullable(number) });

export const statusSalarySuccessResponseDecoder = jsendSuccessDecoder(statusSalarySuccessDecoder);

export type StatusSalarySuccessResponse = DecoderType<typeof statusSalarySuccessResponseDecoder>;

const statusSalaryDecoder = object({ status: registrationStatusDecoder, salary: nullable(number) });

export const statusSalaryResponseDecoder = jsendResponseDecoder(statusSalaryDecoder, string);

export type StatusSalaryResponse = DecoderType<typeof statusSalaryResponseDecoder>;

export interface SaveCertificatePayload {
  amount: FormikMoneyField;
  salary: FormikMoneyField;
  sourceOfFundsDesc: string;
  sourceOfFundsFile: null | undefined;
  term: number;
  liquidation: string;
  typeofPayment: string;
  savingsAccount: string;
  account: string;
  accountNumber: string;
  accountBank: string;
  accountType: string;
}

const distributionDecoder = object({
  values: array(
    tuple(
      string, // id
      string, // heading
      number // quota
    )
  ),
  total: tuple(string, number),
});

const affiliationOptionsDecoder = object({
  initial: object({
    loanIncludesFees: boolean,
    distribution: distributionDecoder,
  }),
  monthly: object({
    monthlyDiscount: object({
      max: integer,
      step: integer,
    }),
    distribution: distributionDecoder,
  }),
});

export type AffiliationOptions = DecoderType<typeof affiliationOptionsDecoder>;
export const affiliationOptionsResponseDecoder = jsendSuccessDecoder(affiliationOptionsDecoder);
export type AffiliationOptionsResponse = DecoderType<typeof affiliationOptionsResponseDecoder>;
