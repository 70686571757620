import { Button, Grid, MenuItem } from "@mui/material";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import FormikSelect from "Shared/FormikSelect/FormikSelect";
import FormikSubmitButton from "Shared/FormikSubmitButton/FormikSubmitButton";
import FormikTextField from "Shared/FormikTextField/FormikTextField";
import { useGoBack } from "../ActionButtons/ActionButtons";
import { clientFormSchema, StepFormProps } from "./interfaces";

export function PersonalForm<T extends FormikValues>(props: StepFormProps<T>) {
  const {
    options: { nationalities, professions },
    onSubmit,
    initialValues,
  } = props;
  const { goBack, goBackPending } = useGoBack();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: T, { setSubmitting }: FormikHelpers<T>) => {
        if (goBackPending) return setSubmitting(false);
        onSubmit(values, "personal");
        setSubmitting(false);
      }}
      validationSchema={clientFormSchema.personal}
    >
      <Grid container width="100%" spacing={2} component={Form}>
        <Grid item sm={6} width="100%">
          <FormikTextField name="firstName" id="firstName" label="Nombre:" variant="outlined" required disabled />
        </Grid>
        <Grid item sm={6} width="100%">
          <FormikTextField name="lastName" id="lastName" label="Apellidos:" variant="outlined" required disabled />
        </Grid>

        <Grid item sm={12} width="100%">
          <FormikTextField
            name="dateOfBirth"
            id="dateOfBirth"
            fullWidth
            label="Fecha de nacimiento:"
            variant="outlined"
            type="date"
            required
            disabled
          />
        </Grid>
        <Grid item sm={6} width="100%">
          <FormikSelect
            id="nationality"
            name="nationality"
            label="Nacionalidad:"
            required
            displayEmpty
            placeholder="Seleccionar"
          >
            {nationalities.map((nationalityOption) => (
              <MenuItem key={nationalityOption.core_id} value={nationalityOption.name}>
                {nationalityOption.name}
              </MenuItem>
            ))}
          </FormikSelect>
        </Grid>
        <Grid item sm={6} width="100%">
          <FormikSelect
            id="profession"
            name="profession"
            label="Profesión:"
            required
            displayEmpty
            placeholder="Seleccionar"
          >
            {professions.map((professionOption) => (
              <MenuItem key={professionOption.unique_id} value={professionOption.name}>
                {professionOption.name}
              </MenuItem>
            ))}
          </FormikSelect>
        </Grid>
        <Grid item xs={6} width="100%">
          <Button fullWidth variant="outlined" onClick={() => void goBack()} disabled={goBackPending}>
            Atrás
          </Button>
        </Grid>
        <Grid item xs={6} width="100%">
          <FormikSubmitButton fullWidth variant="contained">
            Siguiente
          </FormikSubmitButton>
        </Grid>
      </Grid>
    </Formik>
  );
}
