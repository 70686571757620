import { Box, Stack, Step, StepButton, StepLabel, Stepper } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { FormikValues } from "formik";
import NavBar from "Layout/NavBar/NavBar";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { ClientFormData, RegistrationModel } from "Services/api/register/interfaces";
import {
  getClientData,
  getClientFormOptions,
  saveAddressData,
  savePersonalData,
  saveWorkData,
} from "Services/api/register/register";
import { AddressForm } from "Shared/AddressForm/AddressForm";
import { Loading } from "Shared/Loading/Loading";
import { Query } from "Shared/Query/Query";
import { SectionTitle } from "Shared/styled";
import { useRegistrationContext } from "../Registration";
import { StepFormProps } from "./interfaces";
import { PersonalForm } from "./PersonalForm";
import { Connector, StepIcon } from "./styled";
import { WorkForm } from "./WorkForm";

const steps = ["Datos del socio", "Datos de residencia", "Datos laborales"];

const Forms: [keyof ClientFormData, <T extends FormikValues>(props: StepFormProps<T>) => JSX.Element][] = [
  ["personal", PersonalForm],
  ["address", AddressForm],
  ["work", WorkForm],
];

const initialValues: ClientFormData = {
  personal: {
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    nationality: "",
    profession: "",
  },
  address: {
    country: "República Dominicana",
    region: "",
    province: "",
    city: "",
    sector: "",
    line1: "",
    line2: "",
    addressCert: undefined,
  },
  work: {
    salary: null,
    salaryWasCaptured: false,
    dateOfHire: "",
    dateOfHireWasCaptured: false,
    paymentType: "",
    sessionType: "",
    position: "",
    department: "",
    shouldCaptureInstitution: false,
    institutionEmail: "",
    institutionPhone: "",
    economicSector: "",
  },
};

export default function ClientForm(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [activeStep, setActiveStep] = useState(0);
  const [{ id, purpose, institutionIsAffiliated }, setRegContext] = useRegistrationContext();
  const [formData, setFormData] = useState(initialValues);

  const requiredCert = !institutionIsAffiliated && purpose === "financing";

  const result = useQuery({
    queryKey: [getFormData.name],
    queryFn: async () => {
      try {
        const { data, options } = await getFormData(id);
        setFormData(data);
        return options;
      } catch (error) {
        enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
        console.error(error);
        throw error;
      }
    },
    gcTime: 0,
  });

  const FormElement = Forms[activeStep][1];
  const onBack = () => {
    if (activeStep > 0) setActiveStep(activeStep - 1);
  };

  return (
    <NavBar>
      <Box sx={{ width: "100%", background: "#87A544", pt: 10, pb: "15px" }}>
        <Stepper activeStep={activeStep} alternativeLabel connector={<Connector />}>
          {steps.map((label, index) => (
            <Step sx={{ display: "flex", justifyContent: "center" }} key={label}>
              <StepButton onClick={() => setActiveStep(index)} sx={{ width: "fit-content" }}>
                <StepLabel StepIconComponent={StepIcon} componentsProps={{ label: { style: { color: "#fff" } } }}>
                  {label}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Query
        result={result}
        OnLoading={() => <Loading />}
        onSuccess={(options) => (
          <Stack justifyContent="center" alignItems="start" sx={{ px: "48px", pt: "25px" }}>
            <SectionTitle variant="h1">{steps[activeStep]}</SectionTitle>

            {activeStep === 1 ? (
              <AddressForm
                options={options}
                initialValues={formData[Forms[activeStep][0]]}
                onSubmit={(values, form) => void onFormElementSubmit(values, form)}
                onBack={onBack}
                requiredCert={requiredCert}
              />
            ) : (
              <FormElement
                options={options}
                initialValues={formData[Forms[activeStep][0]]}
                onSubmit={(values, form) => void onFormElementSubmit(values, form)}
                onBack={onBack}
              />
            )}
          </Stack>
        )}
        onError={() => <div>Ha ocurrido un error obteniendo los datos del formulario...</div>}
      />
    </NavBar>
  );

  async function onFormElementSubmit(
    values: ClientFormData["personal"] | ClientFormData["address"] | ClientFormData["work"],
    form: keyof ClientFormData
  ) {
    try {
      if (form === "personal") {
        values = values as ClientFormData["personal"];
        await savePersonalData(id, values);
        setFormData({ ...formData, personal: values });
        setActiveStep(activeStep + 1);
      } else if (form === "address") {
        values = values as ClientFormData["address"];
        const requestData = new FormData();
        requestData.append("id", id);

        for (const key in values) {
          const el = values[key as keyof ClientFormData["address"]];
          if (el) requestData.append(key, el);
        }

        await saveAddressData(requestData);
        setFormData({ ...formData, address: values });
        setActiveStep(activeStep + 1);
      } else if (form === "work") {
        values = values as ClientFormData["work"];
        const requestData = new FormData();
        requestData.append("id", id);

        for (const key in values) {
          const el = values[key as keyof ClientFormData["work"]];
          if (el && typeof el !== "boolean") requestData.append(key, typeof el === "number" ? el.toString() : el);
        }
        const {
          data: { status },
        } = await saveWorkData(requestData);
        setFormData({ ...formData, work: values });
        setRegContext({ status });
      }
    } catch (error) {
      enqueueSnackbar("Ha ocurrido un error", { variant: "error" });
      console.error(error);
    }
  }
}

async function getFormData(id: RegistrationModel["id"]) {
  const { data: options } = await getClientFormOptions();
  const { data } = await getClientData(id);
  return {
    data,
    options,
  };
}
